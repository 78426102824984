// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import parseXML from "xml-parse-from-string";

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faArrowRight       as fasFaArrowRight          } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faBars             as fasFaBars                } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown        as fasFaCaretDown           } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft        as fasFaCaretLeft           } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight       as fasFaCaretRight          } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion   as fasFaCircleQuestion      } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser       as fasFaCircleUser          } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faClock            as fasFaClock               } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment          as fasFaComment             } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder           as fasFaFolder              } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMagnifyingGlass  as fasFaMagnifyingGlass     } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faTag              as fasFaTag                 } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faXmark            as fasFaXmark               } from "@fortawesome/pro-solid-svg-icons/faXmark";

import { faCompassDrafting  as falFaCompassDrafting     } from "@fortawesome/pro-light-svg-icons/faCompassDrafting";
import { faCamera           as falFaCamera              } from "@fortawesome/pro-light-svg-icons/faCamera";
import { faClipboardList    as falFaClipboardList       } from "@fortawesome/pro-light-svg-icons/faClipboardList";
import { faUserHelmetSafety as falFaUserHelmetSafety    } from "@fortawesome/pro-light-svg-icons/faUserHelmetSafety";
import { faGears            as falFaGears               } from "@fortawesome/pro-light-svg-icons/faGears";

import svgMenuIcon       from "../../media/fac/fac-menu-icon.svg";

/**
 * Add solid icons
 */
library.add(fasFaArrowRight, fasFaBars, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaComment, fasFaFolder, fasFaMagnifyingGlass, fasFaTag, fasFaXmark);

/**
 * Add light icons
 */
library.add(falFaCompassDrafting, falFaCamera, falFaClipboardList, falFaUserHelmetSafety, falFaGears);

library.add({
    "menu-icon": {
        iconName: "menu-icon",
        prefix: "fac",
        icon: [
            svgMenuIcon.attributes.viewBox.split(" ")[2],
            svgMenuIcon.attributes.viewBox.split(" ")[3],
            [],
            "e000",
            parseXML(svgMenuIcon.content).firstElementChild.getAttribute("d"),
        ]
    },
});

/**
 * Watch the DOM to insert icons
 */
dom.watch();
